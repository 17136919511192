<template>
  <MainCard class="pa-6">
    <template v-slot:body>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <h2>Hasil Vote</h2>
              <small>Halaman untuk hasil pemilihan</small>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="search"
                :append-icon="icons.mdiMagnify"
                label="Search"
                single-line
                hide-details
                dense
                outlined
                class=""
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(itemResultEvoting, index) in searchedEvoting"
          :key="index"
          cols="4"
        >
          <v-card
            height="100%"
          >
            <v-card-title>
              {{ itemResultEvoting.name }}
            </v-card-title>
            <v-card-subtitle>
              Hasil Vote {{ itemResultEvoting.name }}
            </v-card-subtitle>
            <v-card-text>
              <apex-chart-expense-ratio :candidate="itemResultEvoting"></apex-chart-expense-ratio>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </MainCard>
</template>

<script>
import { mdiMagnify } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import MainCard from '../../components/MainCard.vue'
import ApexChartExpenseRatio from './ApexChartExpenseRatio.vue'

export default {
  name: 'EvotingResult',
  components: {
    ApexChartExpenseRatio,
    MainCard,
  },
  setup(props, context) {
    const icons = {
      mdiMagnify,
    }

    const page = ref(1)
    const search = ref('')
    const totalPages = ref(0)
    const totalItems = ref(0)
    const isLoadingCard = ref(true)
    const isLoadingData = ref(false)
    const isLoadingButton = ref(false)

    const classUuid = ref('')

    const resultsEvoting = ref([])
    const school = ref('')
    const schoolUuid = ref('')
    const userUuid = ref('')

    const getSchoolUuid = () => {
      school.value = JSON.parse(localStorage.getItem('user')).user.details
      school.value.forEach(el => {
        if (el.school === undefined) {
          schoolUuid.value = el.uuid

          return
        }

        if (el.school === null) {
          return
        }
        userUuid.value = JSON.parse(localStorage.getItem('user')).user.uuid
        schoolUuid.value = el.school.uuid
      })

      return true
    }

    const checkClassUuid = () => {
      if (!JSON.parse(localStorage.getItem('user')).user.details[0].teacher?.class_advisor) {
        classUuid.value = ''
      } else {
        classUuid.value = JSON.parse(localStorage.getItem('user')).user.details[0].teacher.class_advisor[0].uuid
      }
    }

    const listResultEvoting = async (params = {}) => {
      isLoadingData.value = true
      await context.root.$services.ApiServices.list('evoting', {
        ...params,
        class: classUuid.value,
        page: page.value,
      }).then(
        ({ data }) => {
          resultsEvoting.value = data.data.map((resultsEvoting, index) => ({
            ...resultsEvoting,
            index: index + 1,
          }))
          totalItems.value = data.meta.total
          totalPages.value = data.meta.last_page
        },
        err => {
          console.error(err)
          showSnackbar({
            text: 'Anda Bukan Wali Kelas',
            color: 'error',
          })
        },
      )
      isLoadingCard.value = false
      isLoadingData.value = false
    }

    const showSnackbar = snackbarData => {
      context.root.$store.dispatch('snackbar/showSnackbar', snackbarData)
    }
    const searchedEvoting = computed(
      () =>
        // eslint-disable-next-line implicit-arrow-linebreak
        resultsEvoting.value.filter(result => result.name.toLowerCase().indexOf(search.value.toLowerCase()) !== -1),
      // eslint-disable-next-line function-paren-newline
    )

    watch(page, listResultEvoting)

    onMounted(() => {
      getSchoolUuid()
      checkClassUuid()
      listResultEvoting()
    })

    return {
      getSchoolUuid,
      school,
      schoolUuid,
      userUuid,
      classUuid,
      checkClassUuid,
      icons,
      page,
      watch,
      search,
      onMounted,
      searchedEvoting,
      isLoadingData,
      isLoadingCard,
      isLoadingButton,
      listResultEvoting,
      showSnackbar,
      resultsEvoting,
    }
  },
}
</script>

<style>
</style>
